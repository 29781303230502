import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { styled } from '@mui/material/styles'

import { Layout } from 'components'

import {
  AboutUsSection,
  ContactUsSection,
  HeroSection,
  WhatWeDoSection,
} from 'sections'

const Background = styled(BgImage)({
  '&::before': {
    width: '75% !important',
    left: '25% !important',
  },
  '&::after': {
    width: '75% !important',
    left: '25% !important',
  },
})

const IndexPage = ({ data: { backgroundImageData } }) => {
  const backgroundImage = getImage(backgroundImageData)

  return (
    <Background
      image={backgroundImage}
      style={{ backgroundPosition: '0% 0%', backgroundSize: 'contain' }}
    >
      <Layout>
        <HeroSection />
        <WhatWeDoSection />
        <AboutUsSection />
        <ContactUsSection />
      </Layout>
    </Background>
  )
}

export const query = graphql`
  query {
    backgroundImageData: file(relativePath: { eq: "mesh.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default IndexPage
