import React from 'react'
import { Typography, Card, Grid, Box } from '@mui/material'

import { ButtonChevron, SectionHeader, Svg } from '@massappeal/amazon'

import { Section } from 'components'

import ResearchSvg from 'img/icons/research.svg'
import InfrastructureSvg from 'img/icons/infrastructure.svg'
import PromoteSvg from 'img/icons/promote.svg'

import { contactEmail } from 'src/config'

const solutions = [
  {
    icon: ResearchSvg,
    title: 'Conduct case research to create access to justice',
  },
  {
    icon: PromoteSvg,
    title: 'Provide various build building solutions ',
  },
  {
    icon: InfrastructureSvg,
    title: 'Offer a secure claim management platform',
  },
]

const SolutionCard = ({ icon, title, ...props }) => (
  <Card
    elevation={1}
    sx={{
      padding: {
        xs: 2,
        md: 3,
      },
      height: '100%',
    }}
    {...props}
  >
    <Svg
      svg={icon}
      sx={{
        mb: {
          xs: 1,
          md: 2,
        },
      }}
    />
    <Typography variant="h3">{title}</Typography>
  </Card>
)

const WhatWeDoSection = () => (
  <Section>
    <SectionHeader id="what-we-do" title="What we do" titleVariant="h2" />

    <Box
      sx={{
        my: {
          md: 4,
          xs: 3,
        },
      }}
    >
      <Grid container spacing={4}>
        {solutions.map((solution, index) => (
          <Grid item md xs={12} key={index}>
            <SolutionCard icon={solution.icon} title={solution.title} />
          </Grid>
        ))}
      </Grid>
    </Box>
    <ButtonChevron
      color="secondary"
      variant="outlined"
      href={`mailto: ${contactEmail}`}
    >
      Looking to learn more? Get in touch
    </ButtonChevron>
  </Section>
)

export default WhatWeDoSection
