import React from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Typography, Grid, Box } from '@mui/material'

import { Button, SectionHeader } from '@massappeal/amazon'

import { Section } from 'components'

const HeroSection = () => (
  <Section>
    <Box maxWidth={600}>
      <SectionHeader
        title="Advancing access to justice to anyone"
        titleVariant="h1"
      />
      <Typography
        variant="subtitle1"
        sx={{
          my: {
            xs: 2,
            md: 4,
          },
        }}
      >
        We develop technology and partner with representative organisations,
        litigation funders and lawyers involved in class action and group
        litigation throughout Europe.
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="#contact-us"
            endIcon={<ArrowForwardIcon />}
          >
            Get in touch
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Section>
)

export default HeroSection
