import React from 'react'

import { Typography } from '@mui/material'

import { SectionHeader } from '@massappeal/amazon'

import { Section } from 'components'

const AboutUsSection = () => (
  <Section>
    <SectionHeader
      id="about-us"
      title="Creating a just world"
      tagline="About us"
      taglineVariant="tag"
    />
    <Typography paragraph variant="subtitle1">
      We take a technology approach to collective redress. In doing so, we aim
      to make collective redress fair and accessible to everyone.
    </Typography>
    <Typography paragraph variant="subtitle1">
      Since our founding in 2019, we have been involved in various cases with
      multiple organisations throughout Europe.
    </Typography>
    <Typography variant="subtitle1">
      We’re based in Amsterdam, The Netherlands.
    </Typography>
  </Section>
)

export default AboutUsSection
